import './Footer.css';
import { Button } from './Button'; // Fixed import path
import React from 'react';

function Footer() {
  return (
    <div className='footer-container'>
      <section className='footer-subscription'>
        <h1 className='footer-heading'>Get a Cleaning Quote!</h1>
        <div className='input-areas'>
          <form
            name='contact-v2'
            method='POST'
            data-netlify='true'
            netlify-honeypot='bot-field'
            action='https://www.maniquecleaning.com/'
          >
            <input
              type='hidden'
              name='form-name'
              value='contact-v2'
            />
            <div hidden>
              <input name='bot-field' />
            </div>
            <p>
              <label>
                Your Name:{' '}
                <input
                  className='footer-input'
                  type='text'
                  name='name'
                  required
                />
              </label>
            </p>
            <p>
              <label>
                Your Email:{' '}
                <input
                  className='footer-input'
                  type='email'
                  name='email'
                  required
                />
              </label>
            </p>
            <p>
              <label>
                Your Phone Number:{' '}
                <input
                  className='footer-input'
                  type='tel'
                  name='phone'
                  required
                />
              </label>
            </p>
            <p>
              <label>
                Preferred Contact Method:
                <select
                  className='footer-select'
                  name='contact-method'
                  required
                >
                  <option
                    value=''
                    disabled
                    selected
                  >
                    Preferred Contact Method
                  </option>
                  <option value='E-mail'>E-mail</option>
                  <option value='Text'>Text Message</option>
                  <option value='Whatsapp'>WhatsApp Messenger</option>
                </select>
              </label>
            </p>
            <p>
              <Button
                buttonStyle='btn--gradient-primary'
                type='submit'
              >
                Get Started
              </Button>
            </p>
          </form>
        </div>
      </section>
      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-logo'>
            <a
              href='/'
              className='social-logo'
            >
              Manique's
              <i className='fa-solid fa-spray-can-sparkles footer-icon' />
              Cleaning
            </a>
          </div>
          <small className='website-rights'>
            Manique's Cleaning LLC © 2024
          </small>
          <div className='social-icons'>
            <a
              className='social-icon-link'
              href='/'
              target='_blank'
              aria-label='Facebook'
            >
              <i className='fab fa-facebook-f' />
            </a>
            <a
              className='social-icon-link'
              href='/'
              target='_blank'
              aria-label='Instagram'
            >
              <i className='fab fa-instagram' />
            </a>
            <a
              className='social-icon-link'
              href='/'
              target='_blank'
              aria-label='Youtube'
            >
              <i className='fab fa-youtube' />
            </a>
            <a
              className='social-icon-link'
              href='/'
              target='_blank'
              aria-label='Twitter'
            >
              <i className='fab fa-x-twitter' />
            </a>
            <a
              className='social-icon-link'
              href='/'
              target='_blank'
              aria-label='LinkedIn'
            >
              <i className='fab fa-linkedin' />
            </a>
          </div>
        </div>
      </section>
      <small className='footer-banner'>
        "I can do all things through Christ who gives me strength" Philippians
        4:13
      </small>
    </div>
  );
}

export default Footer;

import React from 'react';
import './PriceCard.css';
import './Button.css';
import { Button } from './Button.js';

const PriceCardItem = (props) => {
  const { objProp } = props;
  const {
    title,
    applyGradient,
    type,
    icon,
    para1,
    para2,
    feature_1,
    feature_2,
    feature_3,
    feature_4,
    feature_5,
    feature_6,
    feature_7,
    extra_1,
    extra_2,
    extra_3,
    extra_4,
    extra_5,
  } = objProp;

  return (
    <div className='price__card__item'>
      <div className={`price__card__item--title ${applyGradient}`}>
        <h2>{title}</h2>
      </div>

      <div className='price__card__item--pricing'>
        <div>
          <h3>{type}</h3>
          {icon && <i className={`price__card__item--icon ${icon}`}></i>}
          <h2>{para1}</h2>
          <p>{para2}</p>
        </div>
      </div>
      <div className='price__card__item--btn-container'>
        <Button
          className='price__card__item-button'
          buttonSize='btn--medium'
          buttonStyle='btn--outline-dark'
          link='https://www.trainerize.me/profile/naturalapex/?planGUID=0b41b5732a2641c994b16a6ab532f0b3&mode=checkout'
        >
          Get Started
        </Button>
      </div>
      <div className='price__card__item--features'>
        <ul>
          <li>
            <b>Includes:</b>
          </li>

          {feature_1 && (
            <li>
              <i
                className='fa-solid fa-check'
                style={{ color: 'green' }}
              />
              {feature_1}
            </li>
          )}
          {feature_2 && (
            <li>
              <i
                className='fa-solid fa-check'
                style={{ color: 'green' }}
              />
              {feature_2}
            </li>
          )}
          {feature_3 && (
            <li>
              <i
                className='fa-solid fa-check'
                style={{ color: 'green' }}
              />
              {feature_3}
            </li>
          )}
          {feature_4 && (
            <li>
              <i
                className='fa-solid fa-check'
                style={{ color: 'green' }}
              />
              {feature_4}
            </li>
          )}
          {feature_5 && (
            <li>
              <i
                className='fa-solid fa-check'
                style={{ color: 'green' }}
              />
              {feature_5}
            </li>
          )}
          {feature_6 && (
            <li>
              <i
                className='fa-solid fa-check'
                style={{ color: 'green' }}
              />
              {feature_6}
            </li>
          )}
          {feature_7 && (
            <li>
              <i
                className='fa-solid fa-check'
                style={{ color: 'green' }}
              />
              {feature_7}
            </li>
          )}

          <li>
            <b>Extras:</b>
          </li>
          {extra_1 && (
            <li>
              <i
                className='fa-solid fa-plus'
                style={{ color: 'black' }}
              />
              {extra_1}
            </li>
          )}
          {extra_2 && (
            <li>
              <i
                className='fa-solid fa-plus'
                style={{ color: 'black' }}
              />
              {extra_2}
            </li>
          )}
          {extra_3 && (
            <li>
              <i
                className='fa-solid fa-plus'
                style={{ color: 'black' }}
              />
              {extra_3}
            </li>
          )}
          {extra_4 && (
            <li>
              <i
                className='fa-solid fa-plus'
                style={{ color: 'black' }}
              />
              {extra_4}
            </li>
          )}
          {extra_5 && (
            <li>
              <i
                className='fa-solid fa-plus'
                style={{ color: 'black' }}
              />
              {extra_5}
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default PriceCardItem;

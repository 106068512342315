import PriceCardItem from './PriceCardItem';
import './PriceCard.css';

const PriceCard = () => {
  const allCardsInfo = [
    {
      title: 'Standard Clean',
      type: 'Best Price',
      applyGradient: 'blueGradient',
      para1: 'Regular Cleaning Service',
      para2: 'Keep your home clean and tidy.',
      icon: 'fas fa-broom', // Add icon class
      feature_1: 'Dusting and Vacuuming',
      feature_2: 'Bathroom Cleaning',
      feature_3: 'Kitchen Cleaning',
      feature_4: 'Living Room Cleaning',
      feature_5: 'Bedroom Cleaning',
      feature_6: 'Trash Removal',
      feature_7: 'Flexible Scheduling',
      extra_1: 'Window Cleaning (Exterior)',
      extra_2: 'Kitchen Cabinets',
      extra_3: 'Oven Cleaning',
      extra_4: '',
      extra_5: '',
    },
    {
      title: 'Deep Clean',
      type: 'Thorough Cleaning',
      applyGradient: 'blueGradient',
      para1: 'Intensive Cleaning Service',
      para2: 'Perfect for spring cleaning or special occasions.',
      icon: 'fas fa-spray-can', // Add icon class
      feature_1: 'Detailed Dusting',
      feature_2: 'Appliance Cleaning',
      feature_3: 'Grout and Tile Cleaning',
      feature_4: 'Baseboard and Trim Cleaning',
      feature_5: 'Window Cleaning',
      feature_6: 'Furniture Polishing',
      feature_7: '',
      extra_1: '',
      extra_2: '',
      extra_3: '',
      extra_4: '',
      extra_5: '',
    },
    {
      title: 'Office Cleaning',
      type: 'Business Solution',
      applyGradient: 'blueGradient',
      para1: 'Professional Office Cleaning',
      para2: 'Maintain a clean and productive workspace.',
      icon: 'fas fa-building', // Add icon class
      feature_1: 'Desk and Surface Cleaning',
      feature_2: 'Restroom Sanitization',
      feature_3: 'Breakroom Cleaning',
      feature_4: 'Trash and Recycling Removal',
      feature_5: 'Floor Cleaning',
      feature_6: 'Window and Glass Cleaning',
      feature_7: 'Custom Scheduling',
      extra_1: 'Window Cleaning (Exterior)',
      extra_2: 'Kitchen Cabinets',
      extra_3: 'Oven Cleaning',
      extra_4: '',
      extra_5: '',
    },
    {
      title: 'Post-Construction',
      type: 'Thorough Clean',
      applyGradient: 'blueGradient',
      para1: 'Post-Construction Cleaning',
      para2: 'Get rid of construction dust and debris.',
      icon: 'fas fa-tools', // Add icon class
      feature_1: 'Debris Removal',
      feature_2: 'Dust and Vacuum All Surfaces',
      feature_3: 'Window and Frame Cleaning',
      feature_4: 'Light Fixture Cleaning',
      feature_5: 'Floor and Carpet Cleaning',
      feature_6: 'Sanitization',
      feature_7: 'Detailed Inspection',
      extra_1: 'Window Cleaning (Exterior)',
      extra_2: 'Kitchen Cabinets',
      extra_3: 'Oven Cleaning',
      extra_4: '',
      extra_5: '',
    },
  ];

  return (
    <>
      <div className='price__card__container'>
        {allCardsInfo.map((singleCard) => (
          <PriceCardItem
            objProp={singleCard}
            key={singleCard.title}
          />
        ))}
      </div>
    </>
  );
};

export default PriceCard;

import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {
  return (
    <div className='cards'>
      <h1>Services</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='images/kitchen-white.jpg'
              text='Residential Cleaning'
              label='Relax'
              path='/plans'
              description='This is a short desccription'
            />
            <CardItem
              src='images/dirty-room.jpg'
              text='Commercial Services'
              label='Post-Construction'
              path='/plans'
              description='This is a short desccription'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/planche.png'
              text='Deep Cleaning'
              label='Renew'
              path='/plans'
              description='This is a short desccription'
            />
            <CardItem
              src='images/man-phone-food.jpg'
              text='Dowload our App!'
              label='Online'
              path='/plans'
              description='This is a short desccription'
            />
            <CardItem
              src='images/scale2.jpg'
              text='Power-washing'
              label='Book Now'
              path='https://squareup.com/appointments/book/g071068uw0fljq/L84SN4AG0R5YH/start'
              description='This is a short desccription'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;

import React from 'react'; // This is the main React library.
import '../App.css'; // This is the CSS file for the entire website.
import './HeroSection.css'; // This is the CSS file for this component.
import { Button } from './Button'; // This is the button component.

// This is the HeroSection component.
function HeroSection() {
  return (
    <div className='hero-container'>
      <img
        src='/images/couch-blue.jpg'
        alt='Girl Texting'
        className='hero-image'
      />
      <h1>Your Home Deserves the Best Clean.</h1>
      <p>Experience Impeccable Cleanliness,</p>
      <p>Every Time!</p>
      <div className='hero-btns'>
        <Button
          className='btn'
          buttonSize='btn--large btn--medium'
          link='https://squareup.com/appointments/book/g071068uw0fljq/L84SN4AG0R5YH/start'
        >
          Book a Visit{' '}
          <i
            className='fa-solid fa-clipboard'
            style={{
              background: 'linear-gradient(#36a3dc, #203e78)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          ></i>
        </Button>
        <Button
          className='btn'
          buttonStyle='btn--outline'
          buttonSize='btn--large btn--medium'
          link='/about'
        >
          Learn More{' '}
          <i
            className='fa-solid fa-arrow-right'
            style={{
              background: 'linear-gradient(#36a3dc, #203e78)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          ></i>
        </Button>
      </div>
    </div>
  );
}

export default HeroSection;
